@use "styles" as *;

.container {
  --label-line-width: 100%;
  --label-external-padding-horizontal: 8px;
  --input-border-radius: var(--label-external-padding-horizontal);

  position: relative;
  padding-top: 2px;

  .wrapperLabelField {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 var(--label-external-padding-horizontal);
    width: 100%;
    height: 16px;

    .labelField {
      padding: 0 4px;
      z-index: 1;
      line-height: 0.875rem;
      @include text-xxs-regular;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 10px;
        height: var(--input-border-radius);
        border-top: 0.5px solid $gray;
      }

      &::before {
        inset-inline-start: 0;
        width: var(--label-external-padding-horizontal);
        border-start-start-radius: var(--input-border-radius);
      }

      &::after {
        inset-inline-end: 0;
        width: var(--label-line-width);
        border-start-end-radius: var(--input-border-radius);
      }
    }

    &.readOnly {
      .labelField {
        color: rgba($gray, 0.5);

        &::before,
        &::after {
          border-top-color: rgba($gray, 0.5);
        }
      }
    }

    .mandatory {
      margin-inline-start: 0.4em;
    }

    .tooltipButton,
    .icon {
      width: 16px;
      height: 16px;
    }

    .tooltipContainer {
      display: flex;
      justify-content: flex-start;
      flex-grow: 1;

      .tooltipButton {
        position: relative;
        z-index: 1;
      }

      .tooltipButton::after {
        content: "";
        position: absolute;
        inset: -8px;
      }

      .tooltipButton:focus-visible {
        outline: none;

        .icon svg {
          @include drop-shadow;
          fill: $white;
        }
      }

      .tooltipPanel {
        position: relative;
        z-index: 2;
        color: $dark;
        padding: 8px 12px;
        width: 100%;
        min-width: 200px;
        background-color: $white;
        line-height: 0.875rem;
        @include glass-shadow;
        backdrop-filter: blur(10px);
        @include text-xxs-regular;
        @include rounded-8;

        @include sm-desktop {
          width: 300px;
        }
      }
    }
  }

  .wrapperTextarea {
    height: calc(100% - 16px);
  }

  .wrapperInputField {
    display: flex;
    border: 0.5px solid $gray;
    border-top: 0 none;
    @include rounded-8;
    align-items: center;
    gap: 4px;
    margin-top: -6px;
    transform: translate3d(0, 0, 0);

    &.wrapperInputFieldUneditable {
      color: $gray;
    }

    &.readOnly {
      border-color: rgba($gray, 0.5);
      border-top: 0 none;
    }

    .inputField:only-child {
      padding: 16.5px 12px;
    }

    .inputField,
    .textarea {
      appearance: initial;
      background: initial;
      border: initial;
      padding: 16.5px 0;
      padding-inline-start: 12px;
      width: 100%;
      height: 49px;
      @include rounded-8;
      @include text-s-regular;

      &:focus {
        outline: none;
      }

      &:read-only {
        opacity: 0.5;
      }
    }

    .textarea {
      resize: none;
    }

    .icon {
      margin-inline-end: 8px;
      padding: 4px;
    }

    .buttonIcon {
      z-index: 1;
      display: flex;
      align-items: center;
    }

    .buttonIcon:focus-visible {
      outline: none;

      .icon {
        position: relative;
        z-index: 1;
        color: $white;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        background-color: $error;
        @include rounded-20;
      }
    }

    .submitField {
      @include text-xs-bold-uppercase-cta;

      position: relative;
      border: none;
      white-space: nowrap;
      line-height: 1rem;
      @include ltr-only {
        letter-spacing: 0.16em;
      }
      margin-inline-end: 12px;

      &:focus-visible {
        outline: none;
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }

  .wrapperLabelField.visuallyHidden + .wrapperInputField {
    border: 0.5px solid $gray;
    margin-top: 10px;
  }

  &.containerInFocus {
    .wrapperLabelField {
      .labelField {
        &::before,
        &::after {
          border-top: 0.5px solid $dark;
        }
      }
    }

    .wrapperInputField {
      border: 0.5px solid $dark;
      border-top: 0 none;
    }

    .wrapperLabelField.visuallyHidden + .wrapperInputField {
      border: 0.5px solid $dark;
      margin-top: 10px;
    }
  }

  &.containerDisabled .emptyValueCheckbox input {
    cursor: default;
  }

  .wrapperLabelField {
    .labelField {
      &::before,
      &::after {
        border-top: 0.5px solid $gray;
      }
    }
  }

  .visuallyHidden {
    @include visuallyHidden;

    .labelField {
      &::before,
      &::after {
        border-top: 0 none;
      }
    }
  }

  &.containerWithErrors {
    color: $error;

    .wrapperLabelField {
      .labelField {
        &::before,
        &::after {
          border-top: 0.5px solid $error;
        }
      }

      .tooltipContainer .tooltipButton:focus-visible .icon {
        @include drop-shadow-error;
      }
    }

    .wrapperInputField {
      border: 0.5px solid $error;
      border-top: 0 none;
    }

    .wrapperLabelField.visuallyHidden + .wrapperInputField {
      margin-top: 10px;
      border-top: 0.5px solid $error;
    }
  }
}

// Show / hide password checkbox styles
.containerShowHidePassowrdCheckbox {
  display: block;
  margin-inline-end: 8px;
}

.labelShowHidePassowrdCheckbox {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip-path: circle(0);
  white-space: nowrap;
}

.emptyValueCheckbox {
  margin-block-start: 12px;
}

.informativeMessage {
  margin-top: 4px;
  line-height: 0.875rem;
  @include text-xxs-regular;
}

.successMessage {
  color: $success;
  margin-top: 4px;
  line-height: 0.875rem;
  @include text-xxs-regular;
}

.errorMessage {
  margin-top: 4px;
  color: $error;
  @include text-xxs-regular;
  line-height: 0.875rem;
}

.iconvisibility {
  padding: 4px;
  > svg {
    width: 16px;
    height: 16px;
  }
}

.suggestionsWrapper {
  @include rounded-8;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;

  .suggestionsList {
    list-style-type: none;
    margin: 0;
    padding: 8px;

    .suggestionsItem {
      @include text-s-regular;
      padding: 16px 0;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .suggestionsItem:hover {
      text-decoration: underline;
    }
  }
}

.divider {
  height: 0.5px;
  background-color: $light-gray;
  flex-shrink: 0;
}

.hidden {
  display: none;
}

@use "styles" as *;

.container {
  position: relative;
  display: flex;
  gap: 8px;
  // grid-template-columns: auto 1fr;
  .inputCheckbox {
    position: absolute;
    z-index: 1;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  .inputCheckbox:focus-visible + .icon {
    svg {
      @include drop-shadow;
      fill: $white;
    }
  }

  .errorMessage {
    color: $error;
    grid-column: 2;
  }

  &.containerWithErrors {
    display: grid;
    .icon {
      color: $error;
    }
    .inputCheckbox:focus-visible + .icon {
      svg {
        @include drop-shadow-error;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
  }
}

.labelCheckbox {
  @include text-xs-regular;
  display: flex;
  flex-direction: column;
  gap: 8px;

  strong {
    display: block;
  }

  ul {
    display: flex;
    width: 100%;
    flex-direction: row;
    column-gap: 16px;
    row-gap: 4px;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;

    // @include sm-desktop {
    //   display: grid;
    //   grid-template-columns: repeat(5, 1fr);
    // }

    li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      span {
        font-weight: $font-weight-bold;
      }

      svg {
        margin-inline-end: 4px;
      }
    }
  }
}

@mixin button-shadow {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);
}

@mixin product-shadow-small {
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
}

@mixin product-shadow {
  box-shadow: 10px 10px 22px rgba(0, 0, 0, 0.35);
}

@mixin product-shadow-large {
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.3);
}

@mixin glass-shadow {
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.1);
}

@mixin container-shadow {
  box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
}

@mixin container-shadow-inset {
  box-shadow: inset 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
}

@mixin drop-shadow {
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.56));
}

@mixin drop-shadow-error {
  filter: drop-shadow(0px 0px 8px rgba(204, 12, 12, 0.56));
}

@mixin tab-gradient {
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
}

@mixin sticky-shadow {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
}

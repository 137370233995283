@use "styles" as *;

.container {
  margin: 30px 32px;

  @include sm-desktop {
    margin: 80px 12px;
  }
  @include md-desktop {
    margin: 80px 32px;
  }
  @include lg-desktop {
    margin: 80px auto;
  }
}

.gridContainer {
  gap: 24px;
  @include sm-desktop {
    grid-template-columns: repeat(var(--number-icons), 1fr);
    gap: 12px;
  }
}

.containerIllustration {
  grid-column: 6 span;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;

  @include sm-desktop {
    grid-column: 2 span;
  }
}
.containerIllustration:last-child:nth-child(odd) {
  grid-column: 12 span;

  @include sm-desktop {
    grid-column: 2 span;
  }
}

.illustrationLabel {
  text-align: center;
  @include text-xxs-bold-uppercase;
}

.illustrationIcon {
  stroke-width: 2;

  width: 48px;
  height: 48px;

  @include sm-desktop {
    width: 56px;
    height: 56px;
  }

  svg {
    width: 48px;
    height: 48px;

    @include sm-desktop {
      width: 56px;
      height: 56px;
    }
  }
}

@use "styles" as *;

.wrapperContainer {
  width: 100%;
}

.container {
  padding: 0.5rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
}

.cancelledItem {
  opacity: 0.5;
}

.outOfStockDelivery {
  background: $very-light-gray;
  @include rounded-8;
}

.productLink {
  position: relative;
  display: block;
  height: fit-content;

  @include sm-desktop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.outOfStockIconContainer {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 999px;
  width: 28px;
  height: 28px;

  @include glass-gradient();
}

.infoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 0;
  padding: 4px 0;
}

.productName {
  @include text-s-regular;
}

.colorContainer {
  display: flex;
  align-items: center;
  gap: 4px;

  &.outOfStockDelivery {
    opacity: 50%;
  }
}

.colorPlaceholder {
  height: 20px;
}

.colorImage {
  border-radius: 9999px;
}

.colorName {
  @include text-xs-regular;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  min-height: 20px;
  flex-wrap: wrap;
}

.legacyBottomContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 6px;
  min-height: 20px;
  height: 100%;
  flex-wrap: wrap;
}

.actionsContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
}

.moveToBag {
  min-height: 0;
  padding: 6px 8px;
}

.pricesContainer {
  display: flex;
  align-items: center;
}

.button {
  @include text-xs-regular;

  color: $dark-gray;
  text-decoration: underline;
  white-space: nowrap;
}

.mobileActions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.giftBadge {
  @include color-gradient-light;
  @include text-s-bold-uppercase-cta;
  width: max-content;
  padding: 2px 8px;
  margin-left: auto;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.legacyOrderDetailsDownloadGiftcard {
  @include text-xs-highlighted;
  cursor: pointer;
  width: fit-content;
}

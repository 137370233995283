@use "styles" as *;

.freeLabel {
  @include color-gradient-light;
  padding: 4px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  span {
    @include text-s-bold-uppercase-cta;
  }
}

@use "styles" as *;

.container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.legacyContainer {
  align-self: flex-end;
}

.displayPrice {
  @include text-s-bold;
}

.discount {
  @include text-xxs-bold;
  line-height: unset;
  color: $pink;
}

.originalPrice {
  @include text-xxs-regular;

  color: $dark-gray;
}

@use "styles" as *;

.discountLines {
  display: flex;
  gap: 4px;
  flex-direction: column;
  width: 100%;
}

.discountLine {
  display: flex;
  gap: 1rem;
  align-items: center;
  // padding-left: 40px;
  // padding-right: 0.5rem;
  justify-content: space-between;
}
.name {
  @include text-xxs-bold-uppercase;
  flex-grow: 1;
}
.displayPrice {
  @include text-s-bold;
}
.nameWithLabel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.discountProductContainer {
  background-color: $very-light-gray;
  margin-inline-start: auto;
  width: 100%;
  padding: 4px 8px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  @include rounded-8;
}

.discountProductDescription {
  display: flex;
  gap: 12px;
  align-items: center;
  @include text-s-regular;
}

.discountProductContainer:last-child {
  margin-bottom: 8px;
}

.discountProductAmount {
  @include text-s-bold;
}

@use "styles" as *;

.errorContainer {
  // margin: 16px 0;
  width: 100%;
  color: $error;
  text-align: center;
  @include text-xs-regular;
  line-height: 0.875rem;
}

@use "styles" as *;

.header {
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  transition: transform 0.3s ease;
  will-change: transform;
  @include mobile-only {
    @include glass-shadow;
  }
  &.white {
    .main {
      @include glass-gradient;
      background-repeat: no-repeat;
      background-size: 100% 99.6%;
    }
    .headerNav {
      @include glass-gradient;
    }
  }
  &.gradient {
    .main {
      @include glass-gradient;
    }
    .headerNav {
      @include color-gradient-light-glass;
    }
  }
  &.transparent {
    .main {
      background-color: $white;
    }
  }
  &.inCheckout {
    position: relative;
    box-shadow: initial;
    @include sm-desktop {
      position: fixed;
      @include glass-shadow;
      background-color: $white;
    }
  }
}

.scrolledTop {
  transform: translateY(-#{$header-topbar-mobile});
  @include sm-desktop {
    transform: translateY(-#{$header-topbar-desktop});
  }
}
.scrolledNavBg {
  &.inPdp {
    .headerNav {
      background-position: 50% 100%;
    }
  }
}

.scrolledTotal {
  transform: translateY(-#{$header-mobile});
  @include sm-desktop {
    transform: translateY(-#{$header-desktop});
  }
}

.inPdp {
  .headerNav {
    @include animate;
    transition-delay: 300ms;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.95) 0%,
      rgba(255, 255, 255, 0.65) 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 100% 200%;
    background-position: 50% 200%;
    background-repeat: no-repeat;
    backdrop-filter: blur(10px);
  }
}

.main {
  width: 100%;
}

.mainContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $header-main;

  @include sm-desktop {
    gap: 24px;
  }
}

.center {
  display: flex;
  gap: 1rem;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: pre;
}

.logoWrapper {
  flex-grow: 1;
}

.headerElement {
  padding: 10px;
  display: flex;
  width: 40px;

  @include sm-desktop {
    padding: 0;
    width: auto;
  }

  &[data-desktop-only="true"] {
    display: none;

    @include sm-desktop {
      display: flex;
    }
  }
}

.checkoutButton {
  @include text-xs-regular;
  display: flex;
  gap: 8px;
  align-items: center;
  > span {
    display: none;
  }
  @include sm-desktop {
    > span {
      display: block;
    }
  }
}

.logoIcon {
  height: 24px;

  @include sm-desktop {
    height: 28px;
  }
}

.searchButton {
  @include text-xs-regular;

  align-items: center;
  gap: 8px;
}

.helloContainer {
  display: none;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @include text-xs-regular-uppercase;
  text-transform: uppercase;

  @include sm-desktop {
    display: inline-flex;
  }
}
